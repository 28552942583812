<template>
  <div class="tilbudcont" v-show="isExpiredTilbud && !isExpiredMar25">
    <div id="tilbud" class="tilbud fade-in" style="animation-duration:550ms;">
      <span @click="hide" id="cancel" class="material-symbols-outlined hover"> cancel </span>
      <span id="symbol" class="material-symbols-outlined"> shopping_bag </span>
      <p class="header">Tilbud!</p><br>
      <router-link to="/special" style="text-decoration:none;color:unset;">
        <img src="https://i.imgur.com/uxSGeHK.png">
        <p>Spar <b style="color:#f3ff18;font-size:larger;">50%</b> på alle behandlinger fra Dibi Milano og <b
            style="color:#f3ff18;font-size:larger;">30%</b> på alle produkter fra Dibi Milano.</p>
        <p>Gælder så længe lager haves. </p>
        <p style="font-size:smaller;">Da der er tale om udgående varer og behandlinger, kan noget være udsolgt.</p>
      </router-link>
    </div>
  </div>

  <div class="home">
    <div class="content">
      <FPimg />
      <div id="slideshowcont" class="fade-in-left" style="animation-duration: 600ms;">
        <div class="slideshow">
          <transition name="slide-transition" mode="out-in">
            <div class="slide" :key="currentImage">
              <router-link :to='currentLink'>
                <img :src="currentImage" alt="Slide" class="slide-img" />
                <div id="textcont" class="flexcol">
                  <!--                   <h2 class="slide-text">{{ currentText }} <br> <br>{{ currentText2 }}</h2> -->
                  <h2 class="slide-text">{{ currentText }}</h2>
                  <h2 class="slide-text2">{{ currentText2 }}</h2>
                </div>
              </router-link>
            </div>
          </transition>
        </div>
        <div class="slideshow ssbg">
          <transition name="slide-transition1" mode="out-in">
            <div class="slide" :key="currentImage">
              <router-link :to='currentLink'>
                <img :src="currentImage" alt="Slide" class="slide-img" />
                <div id="textcont" class="flexcol" style="height:500px;">
                  <!--                   <h2 class="slide-text">{{ currentText }} <br> <br>{{ currentText2 }}</h2> -->
                  <h2 class="slide-text">{{ currentText }}</h2>
                  <h2 class="slide-text2">{{ currentText2 }}</h2>
                </div>
              </router-link>
            </div>
          </transition>
        </div>
      </div>
      <br>

      <!-- Social Media box-->
      <!-- <div id="socialmediaboxcont" v-show="!isExpiredApr"> -->
      <div id="socialmediaboxcont" class="fade-in" style="animation-duration: 700ms;">
        <div id="socialmediabox">
          <!-- <h4 style="display:flex;flex-direction:column;align-items:center;">
            <img src="../assets/logo.jpg" style="height:50px;">...er på de sociale medier!
          </h4> -->
          <h4>
            Hudplejeklinikken Esbjerg er på de sociale medier!
          </h4>
          <br>
          <div id="imgbox">
            <a class="socialhover" target="_blank" href="https://www.facebook.com/profile.php?id=100090457474067">
              <img src="../assets/socialicons/facebook.png" style="height:100px;">
            </a>
            <a class="socialhover" target="_blank"
              href="https://www.instagram.com/hudplejeklinikken_esbjerg?igsh=MW52dDc3MTYxcXdldA==">
              <img src="../assets/socialicons/instagram.png" style="height:100px;">
            </a>
          </div>
          <p>
            Det er der du kan følge med i hvad der rører sig i klinikken, herunder nye behandlinger, tilbud på
            produkter,
            lukkedage, events, konkurrencer og meget mere. Så hop ind og følg med.
          </p>
        </div>
      </div>

      <!-- <br> -->
      <div v-show="isExpiredMar && !isExpiredApr" id="dashboard" class="fade-in"
        style="animation-duration: 750ms; padding:10px;">
        <div id="månedstilbud">
          <div class="tilbud" id="septembertilbud" v-show="isExpired1 && !isExpiredSep">
            <router-link to="/fire&ice" style="text-decoration: none; color: black; ">
              <div id="cont" class="pulsating-border22">
                <div id="imgcont">
                  <img src="https://i.imgur.com/XBnWH7e.jpg" alt="september tilbud" style="border-radius: 5px;">
                  <h2>Månedens Behandling</h2>
                  <h3>September tilbud. <br> Book 3 x Fire & Ice - Betal for kun 2.</h3>
                  <div>
                    <p>Tiderne kan placeres til og med december 2023, men SKAL alle bookes i September!</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="tilbud" id="oktobertilbud" v-show="isExpiredSep && !isExpiredOct">
            <router-link to="/defencesolution" style="text-decoration: none; color: black; ">
              <div id="cont" class="pulsating-border22">
                <div id="imgcont">
                  <img src="https://i.imgur.com/0esyUVM.png" alt="september tilbud" style="border-radius: 5px;">
                  <h2>Månedens Behandling - Oktober <br> <b style="font-size: smaller;">Defence Solution
                      Ansigtsbehandling</b> </h2>
                  <div>
                    <p><b style="font-size: 30px;">Kun 500 Kr </b>&nbsp;-&nbsp; SPAR 195,-</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="tilbud" id="novembertilbud" v-show="isExpiredOct && !isExpiredNov">
            <!-- <div class="tilbud" id="novembertilbud" > -->
            <router-link to="/skinpen" style="text-decoration: none; color: black; ">
              <div id="cont" class="pulsating-border22">
                <div id="imgcont">
                  <!-- <img src="https://i.imgur.com/odv7b28.png" alt="september tilbud" style="border-radius: 5px;"> -->
                  <img src="https://i.imgur.com/9eSxu4M.png" alt="september tilbud" style="border-radius: 5px;">
                  <h2>Månedens Behandling - November <br>
                    <b style="font-size: smaller;">SkinPen - Spar 33%</b>
                  </h2>
                  <div style="background-color: unset; outline: unset;">
                    <p><b style="font-size: 30px;">Kun 1000 kr</b>&nbsp;-&nbsp; SPAR 500,-</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="tilbud" id="decembertilbud" v-show="isExpiredNov && !isExpiredDec">
            <router-link to="/hydrafacial" style="text-decoration: none; color: black; ">
              <div id="cont" class="pulsating-border22">
                <div id="imgcont">
                  <!-- <img src="https://i.imgur.com/krwAxp3.png" alt="september tilbud" style="border-radius: 5px;"> -->
                  <img src="https://i.imgur.com/vrXlLoA.jpg" alt="september tilbud" style="border-radius: 5px;">
                  <h2>Månedens Behandling - December <br>
                    <b style="font-size: smaller;">
                      Book 1 HYDRAFACIAL DELUXE og <br> få 1 GRATIS PERK EYE eller PERK LIP</b>
                  </h2>
                  <div>
                    <p><b style="font-size: 30px;">
                        Kun 1350 kr</b>
                      <br>&nbsp;-&nbsp; SPAR 475,- ved Perk Eye
                      <br>&nbsp;-&nbsp; SPAR 350,- ved Perk Lip
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="tilbud" id="januartilbud" v-show="isExpiredDec && !isExpiredJan">
            <!-- <div class="tilbud" id="januartilbud"> -->
            <router-link to="/thegold" style="text-decoration: none; color: black; ">
              <div id="cont" class="pulsating-border22">
                <div id="imgcont">
                  <img src="https://i.imgur.com/6gRYZSd.jpg" alt="januar tilbud" style="border-radius: 5px;">
                  <h2>Månedens Behandling - Januar <br>
                    <b style="font-size: smaller;">
                      The Gold - Spar 22% <br> Special ansigtsbehandling med 24 karat guld</b>
                  </h2>
                  <div>
                    <p><b style="font-size: 30px;">
                        Kun 975 kr</b>&nbsp;-&nbsp; SPAR 275,-
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="tilbud" id="februartilbud" v-show="isExpiredJan && !isExpiredFeb">
            <!-- <div class="tilbud" id="februartilbud"> -->
            <router-link to="/sugaring" style="text-decoration: none; color: black; ">
              <div id="cont" class="pulsating-border22">
                <div id="imgcont">
                  <img src="https://i.imgur.com/Ybm6IAM.png" alt="februar tilbud" style="border-radius: 5px;">
                  <h2>Månedens Behandling - Februar <br>
                    <b style="font-size: smaller;">
                      Spar 20%</b>
                  </h2>
                  <div>
                    <p>PÅ<b style="font-size: 30px;">
                        ALLE</b> SUGARING BEHANDLINGER
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="tilbud" id="martstilbud" v-show="isExpiredFeb && !isExpiredMar">
            <!-- <div class="tilbud" id="Martstilbud"> -->
            <router-link to="/skinpen" style="text-decoration: none; color: black; ">
              <div id="cont" class="pulsating-border22">
                <div id="imgcont">
                  <img src="https://i.imgur.com/9eSxu4M.png" alt="Marts tilbud" style="border-radius: 5px;">
                  <h2>Månedens Behandling - Marts <br>
                    <b style="font-size: smaller;">
                      SkinPen - Spar 25% </b>
                  </h2>
                  <div>
                    <p><b class="b-small" style="font-size: 30px;">
                        Kun 1125 kr</b>&nbsp;-&nbsp; SPAR 375,-
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="tilbud" id="apriltilbud" v-show="isExpiredMar && !isExpiredApr">
            <!-- <div class="tilbud" id="apriltilbud"> -->
            <router-link to="/special" style="text-decoration: none; color: black; ">
              <div id="cont" class="pulsating-border22">
                <div id="imgcont" style="position:relative;">
                  <!-- <img src="https://i.imgur.com/nBFGwVL.png" alt="April tilbud" style="border-radius: 5px;"> -->
                  <img src="https://i.imgur.com/kRkRIQu.jpeg" alt="April tilbud" style="border-radius: 5px;">
                  <h2>Månedens Behandling - April <br>
                    <b style="font-size: smaller;">
                      Alle Dibi Milano Specialbehandlinger - Spar 15% </b>
                  </h2>
                  <div>
                    <p><b class="b-small" style="font-size: 30px;">
                        Fra 591 kr</b>&nbsp;-&nbsp; SPAR OP TIL 185,-<img class="dibi-img" src="../assets/dibi.png"
                        style="position:absolute;right:0;bottom:2px;border:none; width:70px; height:100%;">
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div id="tilbudcont">
          <div id="produkttilbud">
            <div class="slideshow">
              <button @click="prevSlideT" class="nav-button"><span class="material-symbols-outlined">
                  chevron_left
                </span></button>
              <div class="image-container">
                <router-link to="/saleview" style="text-decoration: none; color: black; ">
                  <img :src="currentSlideT" alt="Slideshow" class="slide" />
                </router-link>
                <h2>{{ currentTitleT }}</h2>
                <div class="overlay-text">
                  <p>
                    {{ currentTextT }}
                  </p>
                  <div class="dots">
                    <span v-for="(_, indexT) in imagesT" :key="indexT" class="dot"
                      :class="{ active: indexT === currentIndexT }" @click="goToSlideT(indexT)"><span
                        class="material-symbols-outlined">
                        fiber_manual_record
                      </span></span>
                  </div>
                </div>
              </div>
              <button @click="nextSlideT" class="nav-button"><span class="material-symbols-outlined">
                  navigate_next
                </span></button>
            </div>
          </div>
          <div id="feriestatus">
            <img src="https://i.imgur.com/jjPQ4Ic.png" alt="feriestatus">
            <h2>Lukkedage</h2>
            <div>
              <p class="ld" style="padding:5px 15px;"> 20/3 fra kl. 13.00 </p>
              <p class="ld" style="padding:5px 15px;"> 25/3 - 1/4 (begge dage inklusiv) </p>
              <p class="ld" style="padding:5px 15px;"> 3/4 fra kl. 13.00 </p>
            </div>
          </div>
        </div>
      </div>

      <br>
      <!-- <br> -->
      <div class="gridcon">
        <div class="grid">
          <router-link to="/clinic" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 750ms; cursor:pointer;">
              <p>Klinikken</p>
            </button>
          </router-link>
          <router-link to="/staff" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 850ms; cursor:pointer;">
              <p>Personale</p>
            </button>
          </router-link>
          <router-link to="/brands" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 950ms; cursor:pointer;">
              <p>Mærker</p>
            </button>
          </router-link>
          <router-link to="/products" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1050ms; cursor:pointer;">
              <p>Køb af Gavekort & Produkter</p>
            </button>
          </router-link>
          <router-link to="/policy" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1150ms; cursor:pointer;">
              <p>Handelsbetingelser</p>
            </button>
          </router-link>
          <!-- <router-link to="/temp" style="text-decoration: none; color: inherit;">
          <button class="fade-in" style="animation-duration: 750ms; cursor:pointer;">
            <p>Midlertidige Elevbehandlinger</p>
          </button>
        </router-link> -->
          <router-link to="/facial" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1250ms; cursor:pointer;">
              <p>Ansigtsbehandlinger & Dybderens</p>
            </button>
          </router-link>
          <router-link v-show="!isExpiredDibi" to="/special" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1350ms; cursor:pointer;">
              <p>Dibi Milano Specialbehandlinger</p>
            </button>
          </router-link>
          <router-link to="/fire&ice" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1450ms; cursor:pointer;">
              <p>iS CLINICAL Specialbehandlinger</p>
            </button>
          </router-link>
          <router-link to="/hydrafacial" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1550ms; cursor:pointer;">
              <p>Hydrafacial</p>
            </button>
          </router-link>
          <router-link to="/beautyangel" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1650ms; cursor:pointer;">
              <p>Beauty Angel</p>
            </button>
          </router-link>
          <router-link to="/skinpen" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1750ms; cursor:pointer;">
              <p>SkinPen</p>
            </button>
          </router-link>
          <router-link to="/hairremoval" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1850ms; cursor:pointer;">
              <p>Voksbehandlinger</p>
            </button>
          </router-link>
          <router-link to="/sugaring" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 1950ms; cursor:pointer;">
              <p>Sugaring</p>
            </button>
          </router-link>
          <router-link to="/browslashes" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 2050ms; cursor:pointer;">
              <p>Vipper & Bryn</p>
            </button>
          </router-link>
          <router-link v-show="isExpiredLash" to="/lashlift" style="text-decoration: none; color: inherit;">
            <button class="fade-in" style="animation-duration: 2100ms; cursor:pointer;">
              <p>Last Lift & Brow Lamination</p>
            </button>
          </router-link>
        </div>
      </div>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { computed } from 'vue';
import FPimg from "../components/FPimg.vue";
// import UC from "../components/UC.vue";
export default {
  setup() {

    // tilbuds slideshow 
    const imagesT = ref([
      // 'https://i.imgur.com/swzMoti.jpg', // Forside OLD
      'https://i.imgur.com/afe1B2q.png', // Forside
      'https://i.imgur.com/4YJfpzo.jpg', // iS CLINICAL 
      // 'https://i.imgur.com/dAvG9YG.png', // Dibi Milano 
      'https://i.imgur.com/JpPKuVy.jpg', // Tolure Serum
      //'https://i.imgur.com/vOTk7kL.jpg', // Tolure Serum
      'https://i.imgur.com/5pzO9RG.png', // Julegaveæske1
      'https://i.imgur.com/Lu41qgq.png', // Julegaveæske2
      'https://i.imgur.com/EIGmozB.png', // Julegaveæske3
    ]);
    const titlesT = ref([
      'Produkt Tilbud', // Forside
      'iS CLINICAL Tilbud', // iS CLINICAL 
      // 'Restsalg - På solprodukter fra Dibi Milano', // Dibi Milano 
      'TOLURE SERUM Til Vipper & Bryn', // Tolure Serum
      'Hydra Perfection Gaveæske', // Julegaveæske1
      'Filler Code Gaveæske', // Julegaveæske2
      'Lift Creator Gaveæske ', // Julegaveæske3
    ]);
    const textsT = ref([
      'Scroll igennem for at se nuværende produkt tilbud fra klinikkken.', // Forside
      'For optimal beskyttelse mod forurening, blåt lys og skadelige miljøfaktorer. Giver mere ungdommelig og ensartet hudtone som resultat. Klik for mere info.', // iS CLINICAL 
      // 'Gælder de produkter der er tilbage på hylderne så længe lager haves. Rabatten fratrækkes ved kassen. Klik for mere info.', // Dibi Milano 
      'Skaber tættere bryn og vipper, giver næring og styrker hårene, mm. Klik for mere info.', // Tolure Serum
      'Pris: 447 kr. Spar 30%. Klik for mere info.', // Julegaveæske 1
      'Pris: 697 kr. Spar 34%. Klik for mere info.', // Julegaveæske 2
      'Pris: 677 kr. Spar 38%. Klik for mere info.', // Julegaveæske 3
    ]);

    const currentIndexT = ref(0);

    const currentSlideT = computed(() => imagesT.value[currentIndexT.value]);
    const currentTextT = computed(() => textsT.value[currentIndexT.value]);
    const currentTitleT = computed(() => titlesT.value[currentIndexT.value]);

    const prevSlideT = () => {
      currentIndexT.value = (currentIndexT.value - 1 + imagesT.value.length) % imagesT.value.length;
    };

    const nextSlideT = () => {
      currentIndexT.value = (currentIndexT.value + 1) % imagesT.value.length;
    };
    const goToSlideT = (indexT) => {
      currentIndexT.value = indexT;
    };



    const now = new Date().toLocaleString();
    const deadline = new Date("2023-06-30T23:59:99");

    const isExpired = computed(() => {
      return new Date() > deadline;
    });
    // const deadline1 = new Date("2023-08-31T23:59:99");
    const deadline1 = new Date("2023-08-31T11:59:00");

    const isExpired1 = computed(() => {
      return new Date() > deadline1;
    });

    const deadlineSep = new Date("2023-09-30T11:59:00");

    const isExpiredSep = computed(() => {
      return new Date() > deadlineSep;
    });


    const deadlineOct = new Date("2023-10-31T11:59:00");

    const isExpiredOct = computed(() => {
      return new Date() > deadlineOct;
    });

    const deadlineNov = new Date("2023-11-30T11:59:00");

    const isExpiredNov = computed(() => {
      return new Date() > deadlineNov;
    });

    const deadlineDec = new Date("2023-12-31T11:59:00");

    const isExpiredDec = computed(() => {
      return new Date() > deadlineDec;
    });

    const deadlineJan = new Date("2024-01-31T11:59:00");

    const isExpiredJan = computed(() => {
      return new Date() > deadlineJan;
    });
    const deadlineFeb = new Date("2024-02-29T11:59:00");

    const isExpiredFeb = computed(() => {
      return new Date() > deadlineFeb;
    });
    const deadlineMar = new Date("2024-03-31T11:59:00");

    const isExpiredMar = computed(() => {
      return new Date() > deadlineMar;
    });
    const deadlineApr = new Date("2024-04-30T11:59:00");

    const isExpiredApr = computed(() => {
      return new Date() > deadlineApr;
    });


    const images = [
      {
        src: 'https://i.imgur.com/bQKaQ5t.png', // Beauty Angel
        text: "Oplev lysets magi med den populære Beauty Angel som med sit farvede lys fra 570-850 nanometer påvirker cellemekanismer helt ned til 2 cm's dybde.",
        text2: "Klik for mere info.",
        link: "/beautyangel"
      },
      {
        src: 'https://i.imgur.com/aNnCmgv.jpg', // Skinpen
        // text: "Hudplejeklinikken Esbjerg er glade for nu at kunne tilbyde SkinPen som vores nyeste behandling! Microneedling-teknologi for en smukkere hud.",
        text: "Hudplejeklinikken Esbjerg er stolte over at kunne tilbyde microneedling behandlinger med den godkendte og resultatorienterede SkinPen, for en strålende, ensartet og fastere hud.",
        text2: "Klik for mere info.",
        link: "/skinpen"
      },
      {
        src: 'https://i.imgur.com/RMGw9fl.jpg', // Hydrafacial
        // text: "Hudplejeklinikken Esbjerg tilbyder nu HYDRAFACIAL. Der tilbydes både Signatur behandling, men også Deluxe og Platinum, samt Perk Eye og Perk Lip behandlinger. Klik for mere info.",
        // text: "Hos Hudplejeklinikken Esbjerg udbydes en række unikke Hydrafacial behandlinger, nemlig Signatur, Deluxe og Platinum samt Perk Eye og Perk Lips. Klik for mere info.",
        text: "Hos Hudplejeklinikken Esbjerg udbydes en række unikke Hydrafacial behandlinger, nemlig Signatur, Deluxe og Platinum samt Perk Eye og Perk Lips.",
        text2: "Klik for mere info.",
        link: "/hydrafacial"
      },
      {
        src: 'https://i.imgur.com/In5Dvyh.jpg', // Sugaring (Håndført2) COMPRESSED
        // src: 'https://i.imgur.com/CXdxojT.jpg', // Sugaring (Håndført2)
        // src: 'https://i.imgur.com/9lneQiD.jpg', // Sugaring (Håndført)
        // src: 'https://i.imgur.com/XlAqWpc.jpg', // Sugaring
        // text: "Hudplejeklinikken Esbjerg tilbyder nu SUGARING. Som et af de eneste steder i Danmark udfører vi behandling med håndførte teknikker der understreger skånsomhed og effektivitet i processen.  Klik for mere info.",
        text: "Hudplejeklinikken Esbjerg tilbyder en lang række behandlinger med Sugaring. Som et af de eneste steder i Jylland, benyttes håndført teknik helt uden brug af strips. Dette er din garanti for den allerbedste og mest skånsomme hårfjerningsmetode.",
        text2: "Klik for mere info.",
        link: "/sugaring"
      },
      {
        src: 'https://i.imgur.com/jhmwGpi.jpg', // Is Clinical Products Closeup COMPRESSED
        // src: 'https://i.imgur.com/ExdTffe.jpg', // Is Clinical Products Closeup
        text: "Hos Hudplejeklinikken Esbjerg anvendes lækre og resultatorienterede produkter som Beauty Angel, iS CLINICAL, Dibi Milano, Pandhys og Depilflax.",
        // text: "Hos Hudplejeklinikken Esbjerg anvendes kun de bedste produkter, her inkludere iS CLINICAL, Dibi Milano, Depilflax & PANDHY'S. Klik for mere info.",
        text2: "Klik for mere info.",
        link: "/brands"
      },
      {
        src: 'https://i.imgur.com/BiyRCsN.jpg', // Owner Handling Products COMPRESSED
        // src: 'https://i.imgur.com/gDk62IW.jpg', // Owner Handling Products
        text: 'Hudplejeklinikken Esbjerg er altid klar til at byde dig velkommen med et smil og til at skræddersy den bedste oplevelse for netop dig.',
        // text: 'Hudplejeklinikken Esbjerg er altid klar til at velkomme dig med et smil og skræddersy den bedste oplevelse til netop dig. Klik for mere info.',
        text2: "Klik for mere info.",
        link: "/staff"
      },
      {
        src: 'https://i.imgur.com/2pK0rZz.jpg', // Interior COMPRESSED
        // src: 'https://i.imgur.com/ufVo89U.jpg', // Interior
        text: 'Hos Hudplejeklinikken Esbjerg bliver du mødt med ro, fordybelse og velvære. Derfor er lokalerne indrettet med fokus på hygge, stil og dybde.',
        // text: 'I klinikken tages der specielt højde for dit velvære som kunde og derfor er vores lokation indrettet med fokus på sunde omgivelser. Klik for mere info.',
        text2: "Klik for mere info.",
        link: "/clinic"
      },
    ];

    const currentIndex = ref(0);
    const currentImage = ref(images[currentIndex.value].src);
    const currentText = ref(images[currentIndex.value].text);
    const currentText2 = ref(images[currentIndex.value].text2);
    const currentLink = ref(images[currentIndex.value].link);

    let intervalId;

    const startSlideshow = () => {
      intervalId = setInterval(() => {
        nextSlide();
      }, 15000);
    };

    const slideDirection = ref('next');

    const nextSlide = () => {
      slideDirection.value = 'next';
      setTimeout(() => {
        currentIndex.value = (currentIndex.value + 1) % images.length;
        currentImage.value = images[currentIndex.value].src;
        currentText.value = images[currentIndex.value].text;
        currentText2.value = images[currentIndex.value].text2;
        currentLink.value = images[currentIndex.value].link;
        slideDirection.value = 'reset';
      }, 500); // Adjust the delay to match the CSS transition duration
    };


    // popup
    const hide = () => {
      var cancelElement = document.getElementById("tilbud");
      cancelElement.style.display = "none";
    }
    const deadlineTilbud = new Date("2024-12-22T23:59:00");

    const isExpiredTilbud = computed(() => {
      return new Date() > deadlineTilbud;
    });
    const deadlineMar25 = new Date("2025-03-31T23:59:00");

    const isExpiredMar25 = computed(() => {
      return new Date() > deadlineMar25;
    });

    const deadlineLash = new Date("2025-03-17T23:59:00");

    const isExpiredLash = computed(() => {
      return new Date() > deadlineLash;
    });
    const deadlineDibi = new Date("2025-03-31T23:59:00");

    const isExpiredDibi = computed(() => {
      return new Date() > deadlineDibi;
    });

    onMounted(() => {
      startSlideshow();
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    return {
      now,
      deadline,
      isExpired,
      isExpired1,
      isExpiredSep,
      isExpiredOct,
      isExpiredNov,
      isExpiredDec,
      isExpiredJan,
      isExpiredFeb,
      isExpiredMar,
      isExpiredApr,
      currentImage,
      slideDirection,
      currentText,
      currentText2,
      currentLink,

      currentIndexT,
      currentSlideT,
      currentTextT,
      currentTitleT,
      prevSlideT,
      nextSlideT,
      goToSlideT,
      imagesT,
      titlesT,
      textsT,

      hide,
      deadlineTilbud,
      isExpiredTilbud,
      deadlineMar25,
      isExpiredMar25,
      deadlineLash,
      isExpiredLash,
      deadlineDibi,
      isExpiredDibi
    };
  },

  name: 'HomeView',
  components: {
    // UC,
    FPimg,
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";

.tilbudcont {
  position: fixed;
  // bottom: calc(50% - 55px);
  bottom: 5%;
  right: 0;
  z-index: 999;

  .tilbud {
    font-size: 11px;
    background-color: map-get($cs, logocol2);
    padding: 15px;
    max-width: 220px;
    position: relative;

    .hover:hover {
      background-color: white !important;
      color: map-get($cs, logocol) !important;
      cursor: pointer;
    }

    img {
      max-width: 220px;
      border: 1px solid #50362c;
    }

    #cancel {
      position: absolute;
      color: white;
      z-index: 1;
      font-size: 30px;
      top: 0;
      right: 0;
      background-color: map-get($cs, logocol);
      border-radius: 0 0 0 5px;
    }

    #symbol {
      position: absolute;
      color: rgba(255, 255, 255, 0.289);
      z-index: 1;
      font-size: 80px;
      bottom: 0;
      right: 20px;
    }

    .header {
      position: absolute;
      top: -20px;
      left: 0;
      font-size: 30px;
      background-color: rgb(243, 255, 24);
      // color: map-get($cs, logocol);
      color: Black;
      border: 1px solid map-get($cs, logocol);
      border-radius: 0 5px 5px 0;
      padding: 0 5px;
    }

    p {
      text-align: left;

    }
  }
}

.home {
  margin-top: 120px !important;
  text-align: center;

  @media (max-width: 800px) {
    margin-top: 120px !important;
  }

  #slideshowcont {
    border-top: 5px solid map-get($cs, logocol);
    border-bottom: 1px solid map-get($cs, logocol);

    .ssbg {
      margin-top: -200px !important;
    }

    .slide,
    .slide-img,
    .slide-text {
      margin: 0;
      padding: 0;
    }

    .slideshow {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      overflow: hidden;
      // background-image: map-get($cs, gradient2);
      background-color: rgba(255, 255, 255, 0) !important;
    }

    //  transition 
    .slide-transition-enter-active {
      transition: transform 2s ease;
    }

    .slide-transition-leave-active {
      transition: transform 2s ease;
      transition-delay: 4s;
    }

    .slide-transition-enter-from {
      transform: translateX(100%);
      // transition-delay: -0.5s;
      /* Adjust the delay to match the transition duration */
    }

    .slide-transition-enter-to {
      transform: translateX(0);
    }

    .slide-transition-leave-from {
      transform: translateX(0);
    }

    .slide-transition-leave-to {
      transform: translateX(-100%);
      // transition-delay: 0s;
      /* No delay for the leave animation */
    }



    //  transition 1
    .slide-transition1-enter-active {
      transition: transform 2s ease;
    }

    // .slide-transition1-leave-active {
    //   transition: transform 2s ease;
    // }

    .slide-transition1-enter-from {
      transform: translateX(100%);
      // transition-delay: -0.5s;
      /* Adjust the delay to match the transition duration */
    }

    .slide-transition1-enter-to {
      transform: translateX(0);
    }

    .slide-transition1-leave-from {
      transform: translateX(0);
    }

    .slide-transition1-leave-to {
      transform: translateX(-100%);
      // transition-delay: 1s;
      /* No delay for the leave animation */
    }



    .slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      white-space: wrap;
      border-bottom: 1px solid map-get($cs, logocol);

      .slide-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: inline-block;

      }

      #textcont {
        // background-color: map-get($cs, transparentbg3);

        .slide-text {
          position: absolute;
          // top: 50%;
          top: 40%;
          left: 50%;
          width: 40%;
          transform: translate(-50%, -50%);
          font-size: 16px;
          padding: 5px;
          border-radius: 5px;
          color: map-get($cs, logocol);
          background-color: map-get($cs, transparentbg3);

          @media (max-width: 800px) {
            font-size: 11px;
            width: 70%;
          }
        }

        .slide-text2 {
          position: absolute;
          bottom: -10px;
          left: 50%;
          width: 150px;
          transform: translate(-50%, -50%);
          font-size: 16px;
          padding: 5px;
          border-radius: 5px;
          color: map-get($cs, logocol);
          background-color: map-get($cs, transparentbg3);

          &:hover {
            background-color: map-get($cs, logocol);
            color: white;
            text-shadow: none;
            font-size: 18px;

            @media (max-width: 800px) {
              font-size: 14px;
            }
          }

          @media (max-width: 800px) {
            font-size: 12px;
            width: 115px;
          }
        }
      }
    }
  }

  #socialmediaboxcont {
    display: flex;
    justify-content: center;

    #socialmediabox {
      border: 1px solid map-get($cs, logocol);
      width: 300px;
      border-radius: 5px;
      padding: 10px;

      h4 {
        color: map-get($cs, logocol);
      }

      #imgbox {
        .socialhover {
          &:hover {
            transform: scale(1.1);
          }
        }

        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }

      p {
        font-size: 14px;
        color: map-get($cs, logocol);
      }
    }
  }

  #dashboard {
    display: flex;
    justify-content: center;

    @media (max-width:1000px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width:520px) {
      justify-content: center;
      align-items: center;
    }

    #månedstilbud {
      #januartilbud {
        div {

          div {
            h2 {
              background-color: rgb(255 255 255 / 65%) !important;
            }

            div {
              background-color: rgb(255 255 255 / 65%) !important;
            }
          }
        }
      }

      .tilbud {
        margin: 0 20px !important;
        block-size: fit-content;
        background-color: rgba(0, 0, 0, 0);
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;

        #cont {
          display: flex;

          #imgcont {
            block-size: fit-content;
            background-color: rgba(0, 0, 0, 0);
            padding: 0 !important;
            margin: 0 auto !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 520px;

            @media (max-width:1000px) {
              height: unset;
            }

            img {
              width: 500px;
              height: 100%;
              padding: 0 !important;
              border: 1px solid map-get($cs, logocol);

              @media (max-width:520px) {
                width: 240px;
              }
            }

            position: relative;

            h2 {
              padding: 10px;
              position: absolute;
              top: 0%;
              left: 50%;
              transform: translate(-50%, 0%);
              color: map-get($cs, darktxt);
              // text-shadow:
              //   -1px -1px 0 map-get($cs, darktxt),
              //   1px -1px 0 map-get($cs, darktxt),
              //   -1px 1px 0 map-get($cs, darktxt),
              //   1px 1px 0 map-get($cs, darktxt);
              width: 100%;
              background-color: map-get($cs, transparentbg0);
              border-radius: 5px 5px 0 0;
            }

            h3 {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: map-get($cs, lighttxt);
              text-shadow:
                -1px -1px 0 map-get($cs, darktxt),
                1px -1px 0 map-get($cs, darktxt),
                -1px 1px 0 map-get($cs, darktxt),
                1px 1px 0 map-get($cs, darktxt);
              width: 100%;
              background-color: map-get($cs, transparentbg0);
            }

            div {
              margin: 0 !important;
              position: absolute;
              bottom: 0%;
              left: 50%;
              transform: translate(-50%, 0%);
              width: 100%;
              background-color: map-get($cs, transparentbg0);
              border-radius: 0 0 5px 5px;
              outline: 1px solid map-get($cs, transparentbg0);

              p {
                padding: 10px;
                text-align: left;
                color: map-get($cs, darktxt);
                border-radius: 5px;
                font-size: 16px;
                font-family: Kohinoor Bangla Bol;
                // text-shadow:
                //   -1px -1px 0 map-get($cs, transparentbg3),
                //   1px -1px 0 map-get($cs, transparentbg3),
                //   -1px 1px 0 map-get($cs, transparentbg3),
                //   1px 1px 0 map-get($cs, transparentbg3);
              }
            }

            @media (max-width:520px) {
              .b-small {
                font-size: 18px !important;
              }

              .dibi-img {
                width: 30px !important;
                height: 30px !important;
              }
            }
          }

          #textcont {
            width: 200px;
            // word-wrap: break-word;
            // overflow-wrap: break-word;
            word-break: normal;
            padding: 5px;
            border: 1px solid #155e76;
            border-radius: 0 5px 5px 0;
            color: map-get($cs, logocol);

            p {
              color: map-get($cs, logocol);
              text-align: left;
              font-size: 16px;

              @media (max-width:520px) {
                font-size: 11px;
              }
            }

            @media (max-width:520px) {
              width: 250px;

              .tilbud {
                margin: 0 !important;
              }


              h2 {
                font-size: 14px;
              }
            }
          }

          @media (max-width:520px) {
            margin: 0 !important;

            h2 {
              font-size: 13px;
            }

            h3 {
              font-size: 13px;
            }

            div {
              p {
                font-size: 13px !important;
              }
            }

            .tilbud {
              margin: 0 !important;
            }
          }
        }
      }
    }

    #tilbudcont {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media (max-width:1000px) {
        margin-top: 25px !important;
      }


      #produkttilbud {
        width: 500px;
        height: 350px;
        margin: 10px 0 10px 0;
        // background-color: map-get($cs, logocol2);
        display: block;

        img {
          box-sizing: border-box;
          border-radius: 5px;
          border: 1px solid map-get($cs, logocol);
          // height: 100%;
          // width: 100%;
          width: 500px;
          height: 360px;

        }

        position: relative;

        .slideshow {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 300px;
          position: relative;
        }

        .image-container {
          position: relative;
          margin-bottom: -65px !important;

          @media (max-width:1000px) {
            margin-top: 30px !important;
            margin-bottom: 0 !important;
          }

          @media (max-width:520px) {
            margin-top: -30px !important;
            margin-bottom: 40px !important;
          }

          h2 {
            width: 100%;
            position: absolute;
            padding: 10px;
            top: 0%;
            left: 50%;
            transform: translate(-50%, 0%);
            color: map-get($cs, darktxt);
            background-color: map-get($cs, transparentbg2);
            // color: map-get($cs, lighttxt);
            // text-shadow:
            //   -1px -1px 0 map-get($cs, darktxt),
            //   1px -1px 0 map-get($cs, darktxt),
            //   -1px 1px 0 map-get($cs, darktxt),
            //   1px 1px 0 map-get($cs, darktxt);
            // -webkit-text-stroke: 1px map-get($cs, logocol);

            @media (max-width:520px) {
              font-size: 18px;
              width: 100%;
            }
          }

          div {
            position: absolute;
            bottom: 0%;
            left: 50%;
            transform: translate(-50%, 0%);
            width: 100%;
            background-color: map-get($cs, transparentbg2);
            border-radius: 0 0 5px 5px;
            outline: 1px solid map-get($cs, transparentbg2);

            p {
              margin-bottom: 10px !important;
              padding: 10px 15px;
              padding-bottom: 30px;
              text-align: left;
              color: map-get($cs, darktxt);
              // color: map-get($cs, lighttxt);
              border-radius: 5px;
              font-size: 16px;
              font-family: Kohinoor Bangla Bol;
              // text-shadow:
              //   -1px -1px 0 map-get($cs, transparentbg3),
              //   1px -1px 0 map-get($cs, transparentbg3),
              //   -1px 1px 0 map-get($cs, transparentbg3),
              //   1px 1px 0 map-get($cs, transparentbg3);
              // -webkit-text-stroke: 1px map-get($cs, logocol);

              @media (max-width:520px) {
                font-size: 13px;
              }
            }


            .dots {
              bottom: 5px !important;
              right: 0 !important;
              height: 30px;
              background-color: unset;
              outline: unset;
              display: flex;
              justify-content: space-around;
              align-items: center;

              .dot {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 12px !important;
                height: 12px !important;
                border-radius: 50%;
                margin: 0 5px;
                cursor: pointer;

                span {
                  // color: map-get($cs, logocol);
                  color: white;
                  font-size: 30px;
                  text-shadow:
                    -1px -1px 0 black,
                    1px -1px 0 black,
                    -1px 1px 0 black,
                    1px 1px 0 black;

                  @media (max-width:520px) {
                    font-size: 25px;
                  }
                }
              }
            }


            .dot.active {
              background-color: map-get($cs, logocol2);
              /* Change this color to your desired active color */
            }
          }
        }

        .slide {
          max-width: 100%;
          max-height: 100%;
        }

        .nav-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: none;
          border: none;
          font-size: 18px;
          cursor: pointer;
          z-index: 99;

          @media (max-width:520px) {
            top: 20%;
          }
        }

        .nav-button:first-child {
          left: 10px;
          height: 50px;

          @media (max-width:520px) {
            height: 100px;
            left: 1px;
          }

          clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
          background-color: map-get($cs, logocol);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .nav-button:last-child {
          right: 10px;
          height: 50px;

          @media (max-width:520px) {
            height: 100px;
            right: 1px;
          }

          clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
          background-color: map-get($cs, logocol);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }



        // .mySlides {
        //   .slide-content {
        //     margin-bottom: -20px !important;

        //     @media (max-width:1000px) {
        //       margin-bottom: 0 !important;
        //     }
        //   }



        // }



        // /* Center the navigation buttons */
        // .prev-button {
        //   float: left;
        //   position: absolute;
        //   border-radius: 5px 0 0 0;
        //   top: 5px;
        //   left: 0%;
        //   transform: translate(-0%, 0%);
        //   background: map-get($cs, gradientslidebtns);
        // }

        // .next-button {
        //   float: right;
        //   position: absolute;
        //   border-radius: 0 5px 0 0;
        //   top: 5px;
        //   right: 0%;
        //   transform: translate(0%, 0%);
        //   background: map-get($cs,  gradientslidebtns);
        // }

        // /* Styles for navigation buttons */
        // .prev-button,
        // .next-button {
        //   // background-color: map-get($cs, logocol);
        //   color: white;
        //   padding: 10px 20px;
        //   border: none;
        //   cursor: pointer;

        //   &:hover {
        //     background: map-get($cs, gradientslidebtnshover);
        //   }

        //   @media (max-width: 1000px) {
        //     top: -16px;
        //   }

        //   @media (max-width: 520px) {
        //     top: -5px;
        //     font-size: 9px;
        //   }
        // }
      }

      // }

      #feriestatus {
        width: 500px;
        height: 150px;
        margin: 10px 0 10px 0;
        // background-color: map-get($cs, logocol2);
        display: inline;

        img {
          border-radius: 5px;
          border: 1px solid map-get($cs, logocol);
          height: 100%;
          width: 100%;
        }

        position: relative;

        h2 {
          position: absolute;
          top: 0%;
          // left: 50%;
          // transform: translate(-50%, 0%);
          padding: 10px 15px;
          left: 0%;
          transform: translate(0%, 0%);
          // color: map-get($cs, lighttxt);
          color: map-get($cs, darktxt);
          // text-shadow:
          //   -1px -1px 0 map-get($cs, darktxt),
          //   1px -1px 0 map-get($cs, darktxt),
          //   -1px 1px 0 map-get($cs, darktxt),
          //   1px 1px 0 map-get($cs, darktxt);
        }

        div {
          position: absolute;
          bottom: 0%;
          left: 50%;
          transform: translate(-50%, 0%);
          width: 100%;
          background-color: map-get($cs, transparentbg0);
          border-radius: 0 0 5px 5px;
          outline: 1px solid map-get($cs, transparentbg0);

          p {
            padding: 10px 15px;
            text-align: left;
            color: map-get($cs, darktxt);
            border-radius: 5px;
            font-size: 16px;
            font-family: Kohinoor Bangla Bol;
            // text-shadow:
            //   -1px -1px 0 map-get($cs, transparentbg3),
            //   1px -1px 0 map-get($cs, transparentbg3),
            //   -1px 1px 0 map-get($cs, transparentbg3),
            //   1px 1px 0 map-get($cs, transparentbg3);
          }

          @media (max-width:520px) {
            .ld {
              font-size: 13px;
            }
          }
        }
      }

      @media (max-width:520px) {
        justify-content: center;

        #tilbudcont2 {
          flex-direction: column;

          #behandlingstilbud {
            width: 250px;
            margin-bottom: 10px !important;
          }

        }

        #produkttilbud {
          width: 250px;
          height: 250px;

          img {
            width: 250px;
            height: 250px;
          }
        }

        #feriestatus {
          width: 250px;
        }
      }
    }
  }

  .gridcon {
    max-width: 700px;
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
    padding: 10px;

    .grid {
      display: grid;
      gap: 5px;
      grid-template-columns: repeat(5, 1fr);

      button {
        width: 200px;
        height: 60px;
        padding: 10px;
        background-color: map-get($cs, logocol);
        color: map-get($cs, wtext);
        font-family: Kohinoor Bangla Lig;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          text-align: center !important;
        }

        &:hover {
          background-color: map-get($cs, logocol2);
          transform: translateX(2px);
          transform: translateY(2px);

          p {
            background: map-get($cs, highlight-logo);
            background-clip: text;
            font-size: larger;
            font-weight: bolder;
            -webkit-text-fill-color: transparent;
          }
        }

        h3,
        p {
          text-align: left;
        }

        p {
          margin: 15px 10px !important;
          font-size: smaller;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width:1100px) {
    .gridcon {
      max-width: 600px;

      .grid {
        gap: 1px;
        grid-template-columns: repeat(2, 1fr);

        div {
          padding: 10px;
          background-color: map-get($cs, logocol);
        }
      }
    }
  }

  @media (max-width:800px) {
    .gridcon {
      max-width: 300px;

      .grid {
        gap: 1px;
        grid-template-columns: repeat(2, 1fr);

        div {
          padding: 10px;
          background-color: map-get($cs, logocol);
        }
      }
    }
  }

  @media (max-width:500px) {
    .gridcon {
      max-width: 300px;

      .grid {
        gap: 1px;
        grid-template-columns: repeat(1, 1fr);

        div {
          padding: 10px;
          background-color: map-get($cs, logocol);
        }
      }
    }
  }

  #footer {
    bottom: 0 !important;
  }
}
</style>